/* styles in src/style directory are applied to the whole page */

/*
body {
  background: #0147A7;
  color: #fff;
}

a {
  color: #03A9F4;
}

main {
  padding: 1em;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  margin-top: 50px;
  display: block;
}

footer {
  text-align: center;
  font-size: 0.8em;
}
*/